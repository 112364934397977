import React, { useEffect, useState } from "react";
import HotelOutlinedIcon from '@mui/icons-material/HotelOutlined';
import Photo from "../../assets/images/photo-icon.svg";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { Button } from 'reactstrap';
import DomainOutlinedIcon from '@mui/icons-material/DomainOutlined';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs'
import Tooltip from "@mui/material/Tooltip";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import AmenitiesSlider from "./amenitiesSlider";
import { fetchBedType, selectAmenitiesIcon } from "../Common/amenities_icon";
import { getS3BaseUrl } from "../../helpers/string_helper";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%', // Adjusted width for responsiveness
    maxWidth: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: '10px'
};

function FloorBedMatrix(props) {
    const [amenityOpen, setAmenityOpen] = useState(false);
    const [selectedAmenities, setSelectedAmenities] = useState([]);
    const [selectedDocuments, setSelectedDocuments] = useState([]);
    const amenityHandleOpen = () => setAmenityOpen(true);
    const amenityHandleClose = () => setAmenityOpen(false);
    const [roomData, setRoomData] = useState();
    const s3BaseUrl = getS3BaseUrl()

    const navigate = useNavigate();
    const handleBookClick = (room) => {
        navigate('/book-room', {
            state: {
                roomData: JSON.stringify({ ...room, owner_id: props?.owner_id })
            },
        });
    };

    useEffect(() => {
        if (props?.rooms) {
            const result = props?.rooms.map((item) => {
                return {
                    ...item,
                    documents: item?.documents?.length > 0 ? item?.documents.map((img) => {
                        return {
                            ...img,
                            location: `${s3BaseUrl}${img.location}`
                        }
                    }) : []
                }
            })
            setRoomData(result)
        }
    }, [props?.rooms])

    return (
        <div className="floorBedMatrix">
            <h5>{props?.flat ? (
                <><span className="flat_color"><DomainOutlinedIcon /> {props.floor === '0th' ? 'Ground' : props.floor} Floor</span><span className="floor_color"> ({props?.flat})</span></>)
                : (<span className="flat_color"><DomainOutlinedIcon /> Floor {props.floor === '0th' ? 'Ground' : props.floor}</span>)
            }
            </h5>
            <div className="mainCardBlock d-flex">
                {roomData?.length > 0 && roomData?.map((item, index) => (
                    <div className="card" key={index}>
                        <div className="fbmRoom d-flex align-items-start">
                            <div className="room_ico"><HotelOutlinedIcon className="me-2" /></div>
                            <div className="fbmRoomDetail">
                                <h5>{item.name}</h5>
                                <p>{item.room_type.name}, {fetchBedType(item.bed_count)}</p>
                            </div>
                            {(item.amenities?.length > 0 || item?.documents?.length > 0) && (
                                <Tooltip title="picture & amenities">
                                    <img src={Photo} alt={Photo} onClick={() => {
                                        setSelectedAmenities(item.amenities)
                                        setSelectedDocuments(item.documents)
                                        amenityHandleOpen()
                                    }} className="cursor-pointer mx-2 fbRoom_img" />
                                </Tooltip>
                            )}
                        </div>
                        {item.beds.map((bed, index) => (
                            <div className="fbmRoombed" key={index}>
                                <div className="d-flex align-items-center justify-content-between fbmRoomBedDetail">
                                    <h5>{bed.name}</h5>
                                    {(bed.vacant_requests?.length > 0 && bed.vacant_requests[0]?.tenant_id === bed?.user?.tenant?.id) && (
                                        dayjs(bed.vacant_requests[0]?.leaving_date) < dayjs() && (
                                            <Button className='book_btn booked_btn' variant="contained">
                                                <span onClick={() => handleBookClick({
                                                    pictures: item.documents,
                                                    room_type: `${item.room_type.name} ${fetchBedType(item.bed_count)}`,
                                                    bed: { bed, flatNo: props.flat, floorNo: props.floor, roomNo: item.name },
                                                    basicDetails: props?.basicDetails
                                                })}
                                                >Book</span>
                                            </Button>
                                        )
                                    )}
                                    {(bed.vacant_requests?.length > 0 && bed.vacant_requests[0]?.tenant_id === bed?.user?.tenant?.id) ? (
                                        (bed.booking_date && dayjs(bed.vacant_requests[0]?.leaving_date) >= dayjs()) && (
                                            <Button className='book_btn not_booked_btn' variant="contained"> <span> Booked by a {bed?.user?.tenant.type}</span></Button>
                                        )
                                    ) : bed.booking_date && (
                                        <Button className='book_btn not_booked_btn' variant="contained"> <span> Booked by a {bed?.user?.tenant.type}</span></Button>
                                    )}
                                    {!bed.booking_date && (
                                        <Button className='book_btn booked_btn' variant="contained">
                                            <span onClick={() => handleBookClick({
                                                pictures: item.documents,
                                                room_type: `${item.room_type.name} ${fetchBedType(item.bed_count)}`,
                                                bed: { bed, flatNo: props.flat, floorNo: props.floor, roomNo: item.name },
                                                basicDetails: props?.basicDetails
                                            })}>Book</span>
                                        </Button>
                                    )}
                                </div>
                                {(bed.vacant_requests?.length > 0 && bed.vacant_requests[0]?.tenant_id === bed?.user?.tenant?.id) ? (
                                    (bed.booking_date && dayjs(bed.vacant_requests[0]?.leaving_date) >= dayjs()) && (<div>
                                        <span style={{ display: 'block', fontSize: '14px', fontWeight:'600', color: '#ED3542' }}>₹ {bed.price}</span>
                                        <span style={{ display: 'block', fontSize: '12px', color: '#6d6e6f' }}>
                                            Available from {dayjs(bed.vacant_requests[0]?.leaving_date).format('DD-MM-YYYY')}</span>
                                    </div>)
                                ) : bed.booking_date && (<div>
                                    <span style={{ display: 'block', fontSize: '14px', fontWeight:'600', color: '#ED3542' }}>₹ {bed.price}</span>
                                </div>)}
                                {(!bed.booking_date && (<span style={{ fontSize: '14px', fontWeight:'600', color: '#ED3542' }}>₹ {bed.price}</span>))}
                                {(bed.vacant_requests?.length > 0 && bed.vacant_requests[0]?.tenant_id === bed?.user?.tenant?.id) && (
                                    dayjs(bed.vacant_requests[0]?.leaving_date) < dayjs() && (<span style={{ fontSize: '12px', color: '#ED3542' }}>₹ {bed.price}</span>))}

                            </div>
                        ))}
                    </div>
                ))}
            </div>

            {/* Amenity Modal open */}
            {amenityOpen && (
                <Modal
                    open={amenityOpen}
                    onClose={amenityHandleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style} className="tenantRemove">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="crossIcon cursor-pointer position-absolute">
                                <CloseOutlinedIcon onClick={amenityHandleClose} />
                            </div>
                        </div>
                        {selectedDocuments?.length > 0 && (
                            <div className={selectedAmenities?.length > 0 ? "amenityImagesSlider mb-3" : "amenityImagesSlider"}>
                                <AmenitiesSlider files={selectedDocuments} />
                            </div>
                        )}
                        {selectedAmenities?.length > 0 && (
                            <div className="detail_block amenityCustomBox py-0">
                                <h6 className="block_heading mb-2">Amenities</h6>
                                <ul className="custom_amenties_options d-flex flex-wrap ps-0 mb-0 gap-2" style={{ listStyle: 'none' }}>
                                    {(selectedAmenities && selectedAmenities?.length > 0) && selectedAmenities.map((item, index) => (
                                        <li className="position-relative d-flex me-1" key={index + 1} style={{
                                            color: '#4c4343'
                                        }}>
                                            {selectAmenitiesIcon(item?.toLowerCase())} <div className="amenity_item ms-1">{item}</div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </Box>
                </Modal>
            )}
        </div>
    )
}

export default FloorBedMatrix;