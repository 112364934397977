import React, { useState } from "react";
import MyLocationOutlinedIcon from '@mui/icons-material/MyLocationOutlined';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import RestaurantOutlinedIcon from '@mui/icons-material/RestaurantOutlined';
import SuitableTags from "../Common/suitableTags";
import Amenities from "./amenities";
import AddressComponent from "../Common/Address";
import { FoodMenuDocumentSlider } from "./foodMenuSlider";
import SuitableTagsDesc from "../Common/suitableTagsDescription";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { useNavigate } from 'react-router-dom';

const PropertyDescription = ({ propertyDetails }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [isFoodMenu, setIsFoodMenu] = useState(false);
    const [foodMenuFiles, setFoodMenuFiles] = useState();
    const navigate = useNavigate();

    const toggleFoodMenu = () => {
        setIsFoodMenu(!isFoodMenu)
    }

    const handleHiddenList = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    console.log('propertyDetails', propertyDetails)

    return (
        <div className="propertyDesc d-flex align-items-start">
            <ArrowBackOutlinedIcon className="me-2" onClick={() => navigate(-1)}
                style={{ cursor: 'pointer' }}/>
            <div className="content-container property_content">
                <div className="property_header descrition_prop_head d-grid">
                    <div className='page_header'>
                        <h2>{propertyDetails?.name}</h2>
                    </div>
                    {propertyDetails?.near_to && (
                        <div className="tags_block d-flex align-items-center">
                            <div className="tag_heading">
                                <MyLocationOutlinedIcon className="main_icon theme_color" />
                                <span className="body_font_md font_bold font_black">Near By</span>
                            </div>
                            <SuitableTagsDesc tags={propertyDetails?.near_to ? propertyDetails?.near_to?.split(',') : []} onClick={handleHiddenList} />
                        </div>
                    )}
                </div>
                <div className="main_desc">
                    <div className="det_desc main_location d-flex mb-2">
                        <RoomOutlinedIcon className="main_icon" />
                        <p style={{ fontSize: '14px'}}>
                            <AddressComponent address={propertyDetails?.address} /> <a href={`https://www.google.com/maps/dir/?api=1&destination=${propertyDetails?.latitude},${propertyDetails?.longitude}&dir_action=navigate`} target="_blank" rel="noreferrer">Google Map View</a>
                        </p>
                    </div>
                    {/* <p style={{ paddingLeft: '25px', marginBottom: '5px'}}></p> */}
                    <div className="det_desc food_service d-flex mb-2">
                        <RestaurantOutlinedIcon className="main_icon" />
                        {propertyDetails?.food_menu_documents?.length > 0 ? (
                            <p style={{ fontSize: '14px'}}><span className="view-menu-button theme_color" onClick={() => {
                                    setFoodMenuFiles(propertyDetails?.food_menu_documents)
                                    toggleFoodMenu()
                                }}>{'View menu card'}</span>
                            </p>
                        ) : (
                            <p style={{ fontSize: '14px'}}>
                                Food Service Not Available
                            </p>
                        )}

                    </div>
                </div>
                {propertyDetails?.amenities?.length > 0 && (
                    <div className="amenities desc_amenities d-flex align-items-center" style={{ marginTop: '30px'}}>
                        <h2 style={{ fontSize: '14px', marginBottom: '5px'}}>Amenities:</h2>
                        <div className="tags_block d-flex align-items-center">
                            <Amenities tags={propertyDetails?.amenities?.length > 0 ? propertyDetails?.amenities : []} onClick={handleHiddenList} />
                        </div>
                    </div>
                )}
            </div>
            

            {isFoodMenu && <FoodMenuDocumentSlider isOpen={isFoodMenu} handleClose={toggleFoodMenu} files={foodMenuFiles} />}
        </div>
    );
};

export default PropertyDescription;
