import React, { useState, useEffect, useMemo, useCallback } from "react";
import { debounce, TextField, Box, Autocomplete, InputAdornment } from '@mui/material';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import SearchIcon from '@mui/icons-material/Search';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import Chip from '@mui/material/Chip';
import LocationSearchingOutlinedIcon from '@mui/icons-material/LocationSearchingOutlined';
import SearchImgIcon from "../../assets/images/search.png";

import {
  setDefaults,
  fromPlaceId,
} from "react-geocode";
import { google } from "../../config";
import { options } from "../../common/constant";
import { getAllProperties } from "../../api/property";


export default function ComboBox({ searchPRopertyData }) {
  const [isOption, setIsOption] = useState(0)
  const [googleData, setGoogleData] = useState();
  const [isMounted, setIsMounted] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [position, setPosition] = useState({
    latitude: '',
    longitude: ''
  })
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (googleData?.value?.place_id && !isMounted) {
      setDefaults({
        key: google.API_KEY, // Your API key here.
        language: "en", // Default language for responses.
        region: "in", // Default region for responses.
      });

      fromPlaceId(googleData?.value?.place_id)
        .then(({ results }) => {
          const { lat, lng } = results[0].geometry.location;
          const address = results[0].formatted_address;
          console.log(lat, lng, 'gajal', results);
          fetchAllProperty({ latitude: lat, longitude: lng })
          setIsMounted(true)
          console.log(address);
        })
    }
  }, [googleData?.value])

  const fetchAllProperty = async (query) => {
    try {
      const response = (await getAllProperties(query)).data.data;
      searchPRopertyData(response);
    } catch (err) {
      console.log(err)
    }
  }

  const getCurrentLocation = async () => {
    setPosition({
      latitude: '',
      longitude: ''
    })
    await navigator.geolocation.getCurrentPosition(function (position) {
      setPosition({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      })
    });
    if (position?.latitude) {
      await fetchAllProperty(position)
    }
  }

  const acValue = useMemo(() => {
    return options.find((option) => option.value === googleData?.value);
  }, [options, googleData?.name]);

  const getOptionsDelayed = useCallback(
    debounce((query) => {
      const search = query.toLowerCase().trim()
      const nameArray = options.filter((item) => (item.name?.toLocaleLowerCase().trim()).includes(search))
      if (nameArray?.length === 0) {
        setGoogleData(search)
        setIsOption(1)
        setIsOpen(true)
        setIsMounted(false)
      }
    }, 1200),
    []
  );

  useEffect(() => {
    if (isMounted) {
      getOptionsDelayed(searchQuery);
    }
  }, [searchQuery]);

  console.log('acValue', acValue)

  const CustomInput = (props) => {
    return (
      <TextField
        {...props}
        InputProps={{
          ...props.InputProps,
          startAdornment: (
            <InputAdornment position="start">
              <PlaceOutlinedIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <Chip style={{ cursor: 'pointer' }} onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                getCurrentLocation()
              }} icon={<LocationSearchingOutlinedIcon style={{ width: '13px' }} />} label="near me" />
            </InputAdornment>
          ),
        }}
        variant="outlined"
        fullWidth
        placeholder="Search with your preferred location"
        sx={{
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'transparent',
            },
            '&:hover fieldset': {
              borderColor: 'transparent',
            },
            '&.Mui-focused fieldset': {
              borderColor: 'transparent',
            },
          },
        }}
      />
    );
  };

  return (
    <div className='search_bar_broadness'>
      {isOption === 0 ? (
          <Autocomplete
          fullWidth
            disablePortal
            id="combo-box-demo"
            value={acValue}
            options={options}
            isClearable={true}
            getOptionLabel={(option) => (option ? option.name : "")}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 }, width: '100%' }}
                key={option.id}
                {...props}
              >
                {option.name}
              </Box>
            )}
            onChange={(event, newValue) => {
              console.log('newValue', newValue)
              if (newValue === undefined) {
                setGoogleData()
                setIsMounted(false)
              } else {
                setGoogleData(newValue)
              }
            }}
            onInputChange={(event, newValue) => {
              console.log('search query', newValue, event.target.value)
              if (newValue?.length >= 4) {
                setIsMounted(true)
              }

              setSearchQuery(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Search with your preferred location"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <PlaceOutlinedIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end" sx={{ color: '#ED3542' }}>
                      <Chip style={{ cursor: 'pointer', marginRight: '10px' }} onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        getCurrentLocation()
                      }} icon={<LocationSearchingOutlinedIcon style={{ width: '16px' }} />} label="near me" />
                      <img src= {SearchImgIcon} alt={SearchImgIcon} />
                    </InputAdornment>
                  ),
                  
                }}
                // inputProps={{
                //   ...params.inputProps,
                //   value: (googleData && googleData?.name) ? `${googleData.name} ${googleData?.address}` : searchQuery
                // }}
              />
            )}
          />
        ):(
        <div className="autocomplete-container" style={{position:'relative', textAlign: 'left', color: '#000', opacity: '1'}}>
          <PlaceOutlinedIcon style={{ position: 'absolute', left: 10, color: '#9e9e9e', zIndex: 9, top: '8px' }} />
          <GooglePlacesAutocomplete
            query={{
              componentRestrictions: {
                latLngBounds: { north: '22.567', south: '88.367', },
                region: 'in',
                strictBounds: true,
              },
                fields: ["address_components", "geometry", "icon", "name"],
                types: ["establishment"],
                language: 'en',
              }}
              minLengthAutocomplete={4}
              getDefaultValue={() => googleData?.value?.structured_formatting ? <div className="custom-autocomplete">{googleData?.value?.structured_formatting?.main_text}</div> : googleData}
              // renderRow={results => {
              //   if (results.value) {
              //     return (
              //       <>
              //         <PlaceOutlinedIcon />
              //         <span>{results.description}</span>
              //       </>
              //     );
              //   } else {
              //     return <span>{results.description}</span>
              //   }
              // }}
              // renderLeftButton={() => (
              //   <PlaceOutlinedIcon />
              // )}
              // open={isOpen}
              isClearable={true}
              selectProps={{
                defaultInputValue: googleData?.value?.structured_formatting ? googleData?.value?.structured_formatting?.main_text : googleData,
                // : 'custom-autocomplete',
                placeholder: "Search with your preferred location",
                name: "name",
                type: 'text',
                // id: "combo-box-demo",
                // styles: { mr: 2, flexShrink: 0, height: '300px' },
                isClearable: true,
                // inputId: "combo-box-demo",

                // googleData,
                onChange: (e) => {
                  console.log('new ggo', e)
                  if (e === null) {
                    setIsOpen(false)
                    setIsMounted(false)
                  }

                  setGoogleData(e)
                  // formik.setFieldValue('name', e.value.structured_formatting?.main_text)
                },
                // components: {
                //   Input: CustomInput,
                // },
                styles: {
                  input: (provided) => ({
                    ...provided,
                    paddingLeft: '40px', // Adjust to make room for the icon
                    paddingRight: '120px', // Adjust to make room for the chip
                  }),
                },
            }}
              apiKey={google.API_KEY}
              // className="custom-menu" 
              // sx={{
              //   '& .MuiOutlinedInput-root': {
              //     '& fieldset': {
              //       borderColor: 'transparent',
              //     },
              //     '&:hover fieldset': {
              //       borderColor: 'transparent',
              //     },
              //     '&.Mui-focused fieldset': {
              //       borderColor: 'transparent',
              //     },
              //   },
              // }}
          />
          <Chip style={{ 
            cursor: 'pointer', 
            position: 'absolute', 
            right: '0px', 
            top: '50%', 
            transform: 'translateY(-50%)', 
            marginRight: '10px' 
          }}  onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            getCurrentLocation()
          }} icon={<LocationSearchingOutlinedIcon style={{ width: '14px' }} />} label="near me" />
          {/* <PlaceOutlinedIcon className="location-icon" /> */}
        </div>
       )} 
    </div>
  );
}

// // Sample locations data
// const locations = ['Testing long text Testing long text Testing long text Testing long text Testing long text Testing long text '];
