import React, { useEffect, useState } from 'react';
import { Container, Card, TextField, Grid, InputLabel, Box, Autocomplete } from '@mui/material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { useNavigate } from 'react-router-dom';
import MyLocationOutlinedIcon from '@mui/icons-material/MyLocationOutlined';
import SuitableTagsDesc from "../../components/Common/suitableTagsDescription";
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import RestaurantOutlinedIcon from '@mui/icons-material/RestaurantOutlined';
import AddressComponent from "../../components/Common/Address";
import { useLocation } from 'react-router-dom';
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import OtherHousesOutlinedIcon from '@mui/icons-material/OtherHousesOutlined';
import HotelOutlinedIcon from '@mui/icons-material/HotelOutlined';
import { CallOutlined, DraftsOutlined } from '@mui/icons-material';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { generateOTP, verifyScheduleVisitOTP } from '../../api/schedule-property-visit';
import { SCHEDULE_PROPERTY_VISIT_OTP_TYPE, TENANT_TYPES, USER_STATUS } from '../../common/constant';
import { contactNoPattern, handleKeyDown } from '../../helpers/string_helper';
import CountdownTimer from '../../components/Common/CountdownTimer';
import dayjs from 'dayjs';
import { AsterikLabel } from '../../components/Common/AsterikLabel';
import { bookingBedForTenant } from '../../api/property';
import { cashfree } from '../../utils/payment';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const FileButton = styled(Button)({

    backgroundColor: '#ECECEC',
    alignItems: 'center',
    color: '#565656',
    border: "1px solid #B1B1B1",
    '&:hover': {
        backgroundColor: '#ECECEC',
        color: '#565656',
        border: '1px solid #B1B1B1',
    },
});

const BookForm = () => {
    const location = useLocation();
    const propertyDetails = location.state?.propertyDetails;
    const [fileName, setFileName] = useState('No file chosen');
    const [anchorEl, setAnchorEl] = useState(null);
    const [isFoodMenu, setIsFoodMenu] = useState(false);
    const [foodMenuFiles, setFoodMenuFiles] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [response, setResponse] = useState()
    const [otp, setOtp] = useState('');
    const [otpExpiresAt, setOTPExpiresAt] = useState({
        status: false,
        contact_number: ''
    });
    const [disableResend, setDisableResend] = useState(true);
    const [resendTime, setResendTime] = useState(30);
    const [isValidOTP, setIsValidOTP] = useState({
        status: false,
        contact_number: ''
    });
    const [invalidOTPerror, setInvalidOTPerror] = useState(null);
    const [preBookingDays, setPreBookingDays] = useState()
    const currentDate = dayjs().subtract(1, 'days')

    useEffect(() => {
        if (location?.state?.propertyDetails) {
            const noticePeriodDate = dayjs().add(propertyDetails?.globalData?.pre_booking?.payload?.value, propertyDetails?.globalData?.pre_booking?.payload?.unit)
            setPreBookingDays({
                date: noticePeriodDate,
                preBookingValue: propertyDetails?.globalData?.pre_booking?.payload
            })
        }
    }, [location.state?.propertyDetails])

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setFileName(file ? file.name : 'No file chosen');
    };
    const navigate = useNavigate();

    const handleHiddenList = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const toggleFoodMenu = () => {
        setIsFoodMenu(!isFoodMenu)
    }

    const initialBookingDetails = {
        joining_date: '',
        name: '',
        contact_number: '',
        email: '',
        type: '',
        address: '',
        documents: '',
        password: '',
        confirm_password: '',
        promoCode: '',
    }
    const passwordPattern = /^(?!.*[\s])(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,16}$/;
    const formik = useFormik({
        initialValues: { ...initialBookingDetails },
        validationSchema: Yup.object({
            name: Yup.string().strict(true).trim('Leading and trailing spaces is not allowed.').required('Please enter name.'),
            contact_number: Yup.string().strict(true).trim('Leading and trailing spaces is not allowed.').matches(contactNoPattern, 'Invalid contact information.').required('Please enter phone number.'),
            email: Yup.string()
                .strict(true)
                .trim('Must not include leading and trailing spaces')
                .email()
                .typeError('Please Enter Valid Email')
                .optional(),
            type: Yup.string().required('Please select tenant type.'),
            joining_date: Yup.date().typeError("Given date is invalid type")
                .min(dayjs(currentDate), `Your Date of joining should be equal to or after current date ${dayjs().format('DD/MM/YYYY')}.`)
                .max(dayjs(preBookingDays?.date), `Pre booking can be done for maximum ${preBookingDays?.preBookingValue?.value} ${preBookingDays?.preBookingValue?.unit}.`)
                .required('Please enter joining date'),
            promoCode: Yup.string().optional(),
            password: Yup.string().required('Please Enter Your Password').matches(passwordPattern, "Password should be 8 character long with maximum 16 character of one capital one small one digit and one special case without whitespace"),
            confirm_password: Yup.string().oneOf([Yup.ref('password')], 'Both Passwords must match').required('Please Enter Your Confirm Password'),
        }),
        validateOnMount: true,
    })

    const sendOTPHandler = async (e, type) => {
        if (!!formik.errors?.phone_no) e.preventDefault();
        try {
            const response = await generateOTP({
                type,
                phone_no: formik.values.contact_number,
            })
            toast.success(response?.data?.message)
            if (response.data.data.expiresAt) {
                setOTPExpiresAt({
                    status: response.data.data.expiresAt,
                    contact_number: formik.values.contact_number
                })
                setOtp()
            }

        } catch (e) {
            if (e?.response?.data?.message) toast.error(e.response.data.message);
            else toast.error(e.message);
        }
    }
    const verifyScheduleVisitOTPHandler = async (otp) => {
        try {
            const response = await verifyScheduleVisitOTP({
                otp,
                phone_no: formik?.values?.contact_number,
            })
            if (response.data) {
                setIsValidOTP({
                    status: true,
                    contact_number: formik?.values?.contact_number,
                });
                setInvalidOTPerror('');
            }
            toast.success(response?.data?.message);
        } catch (e) {
            setIsValidOTP({
                status: false,
                contact_number: ''
            })
            setInvalidOTPerror(e?.response?.data?.message);
        }
    }

    const handleBooking = async () => {
        try {
            setIsLoading(true)
            const payload = {
                joining_date: formik.values.joining_date,
                property_id: propertyDetails?.basicDetails?.basicDetails?.id,
                bed_id: propertyDetails?.basicDetails?.bed?.bed?.id,
                deposit_amount: propertyDetails?.globalData ? parseInt(propertyDetails?.globalData?.deposit_amount?.payload?.value) : 0,
                total_amount: parseInt(propertyDetails?.totalPayableAmount),
                owner_id: propertyDetails?.basicDetails?.owner_id,
                type: formik.values.type,
                ...(formik.values.promoCode && {
                    promoCode: formik.values.promoCode,
                }),
                user: {
                    name: formik.values.name,
                    password: formik.values.password,
                    confirmPassword: formik.values.confirm_password,
                    contact_number: formik.values.contact_number,
                    status: USER_STATUS.INACTIVE,
                    ...(formik.values.address && {
                        address: formik.values.address
                    }),
                    ...(formik.values.email && {
                        email: formik.values.email
                    }),
                    ...(formik.values.documents && {
                        documents: formik.values.documents
                    })
                }
            }

            const response = await bookingBedForTenant(payload)
            if (response.status === 200) {
                let checkoutOptions = {
                    paymentSessionId: response?.data?.data.payment_session_id,
                    order_id: response?.data?.data?.order_id,
                    returnUrl: response?.data?.data?.redirect_url ?? `https://tenants-dev.dstayz.com/login`,
                }

                cashfree.checkout(checkoutOptions).then(function (result) {
                    if (result.error) {
                        setResponse(result)
                        alert(result.error.message)
                    }
                    if (result.redirect) {
                        setResponse(result)
                    }
                })
            } else {
                toast.error(response.data.message);
            }
        } catch (err) {
            toast.error(err.response.data.message);
        }
        finally {
            setIsLoading(false)
        }
    }


    return (
        <div className='detail_pg detail_booking_form'>
            <section className="available_room_detail available_rooms_listing">
                <Container>
                    <div className="propertyDesc d-flex align-items-start">
                        <ArrowBackOutlinedIcon className="me-2" onClick={() => navigate(-1)} style={{ cursor: 'pointer' }} />
                        <div className="content-container property_content">
                            <div className="property_header propDescBookHeader d-flex justify-content-between align-items-start">
                                <div className='page_header'>
                                    <h2>{propertyDetails?.name}</h2>
                                </div>
                                {propertyDetails?.near_to && (
                                    <div className="tags_block d-flex align-items-center">
                                        <div className="tag_heading d-flex">
                                            <MyLocationOutlinedIcon className="main_icon theme_color" />
                                            <span className="body_font_md font_bold font_black" style={{ whiteSpace: 'nowrap' }}>Suitable For</span>
                                        </div>
                                        <SuitableTagsDesc tags={propertyDetails?.near_to ? propertyDetails?.near_to?.split(',') : []} onClick={handleHiddenList} />
                                    </div>
                                )}
                            </div>
                            <div className="main_desc">
                                <div className="det_desc main_location d-flex mb-2">
                                    <RoomOutlinedIcon className="main_icon" />
                                    <p className="body_font_md">
                                        <AddressComponent address={propertyDetails?.address} /> <a href={`https://www.google.com/maps/dir/?api=1&destination=${propertyDetails?.latitude},${propertyDetails?.longitude}&dir_action=navigate`} target="_blank" rel="noreferrer">View in Map</a>
                                    </p>
                                </div>
                                <div className="det_desc food_service d-flex">
                                    <RestaurantOutlinedIcon className="main_icon" />
                                    {propertyDetails?.food_menu_documents?.length > 0 ? (
                                        <p className="body_font_md">Food Service Available
                                            <span className="view-menu-button" onClick={() => {
                                                setFoodMenuFiles(propertyDetails?.food_menu_documents)
                                                toggleFoodMenu()
                                            }}>{'View menu card'}</span>
                                        </p>
                                    ) : (
                                        <p className="body_font_md">
                                            Food Service Not Available
                                        </p>
                                    )}

                                </div>
                            </div>
                            <ul className="bedroom_details list-inline d-flex flex-wrap mb-0">
                                <li><CorporateFareOutlinedIcon /> &nbsp;Floor No. <span>2nd</span></li>
                                <li><BusinessOutlinedIcon /> &nbsp;Flat No. <span>204</span></li>
                                <li><OtherHousesOutlinedIcon /> &nbsp;Room No. <span>5</span></li>
                                <li><HotelOutlinedIcon /> &nbsp;Bed No. <span>3</span></li>
                            </ul>
                        </div>
                    </div>
                    <div className='form_details my-3'>
                        <h4>Please fill up the form to proceed with the booking</h4>
                        <Card>
                            <Grid
                                container
                                spacing={2}
                            >
                                <Grid
                                    item
                                    md={4}
                                    sm={12}
                                >
                                    <div className='icon_input'>
                                        <InputLabel htmlFor="phone" style={{ color: '#1F1F1F' }}>Enter Your Phone No. <AsterikLabel /></InputLabel>
                                        <TextField
                                            id="outlined-required"
                                            placeholder="Phone"
                                            fullWidth
                                            size='small'
                                            name="contact_number"
                                            className="form-control mb-3"
                                            type="text"
                                            disabled={isLoading}
                                            onKeyDown={(e) => { handleKeyDown(e) }}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.contact_number}
                                        />
                                        {formik.touched.contact_number && formik.errors.contact_number && (
                                            <span className="text-danger">{formik.errors.contact_number}</span>
                                        )}
                                        <CallOutlined />
                                    </div>
                                    <div>
                                        <div>
                                            <InputLabel htmlFor="otp" style={{ color: '#1F1F1F' }}>Enter OTP</InputLabel>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    gap: 2,
                                                }}
                                            >
                                                <MuiOtpInput
                                                    TextFieldsProps={{
                                                        size: 'small',
                                                        disabled: !(otpExpiresAt.status && otpExpiresAt.contact_number === formik.values.contact_number),
                                                    }}
                                                    value={otp}
                                                    onChange={(otp) => setOtp(otp)}
                                                    onComplete={(otp) => verifyScheduleVisitOTPHandler(otp)}
                                                    onKeyDown={(e) => { handleKeyDown(e) }}
                                                    length={6}
                                                    disabled
                                                />
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <span className='mt-2'>
                                                        {((otpExpiresAt.status && otpExpiresAt.contact_number === formik.values.contact_number) && resendTime && (!isValidOTP.status)) ?
                                                            <>
                                                                Resend OTP in <CountdownTimer seconds={resendTime} onComplete={() => {
                                                                    setDisableResend(false);
                                                                    setResendTime(0);
                                                                }} />
                                                                <p
                                                                    className='error'
                                                                >
                                                                    {invalidOTPerror}
                                                                </p>
                                                            </>
                                                            :
                                                            <p
                                                                className='error'
                                                            >
                                                                {invalidOTPerror}
                                                            </p>
                                                        }
                                                    </span>
                                                    {(!otpExpiresAt.status || otpExpiresAt.contact_number !== formik.values.contact_number) ? (<Button
                                                        className={`${formik.values?.contact_number ? 'yellow_gradient_btn' : 'disabled'} sign_in_button mt-2`}
                                                        variant="contained"
                                                        disabled={!formik.values?.contact_number || isLoading}
                                                        size='small'
                                                        onClick={(e) => sendOTPHandler(e, SCHEDULE_PROPERTY_VISIT_OTP_TYPE.GENERATE)}
                                                    >
                                                        SEND OTP
                                                    </Button>)
                                                        : (<Button
                                                            className={`${!disableResend ? 'yellow_gradient_btn' : 'disabled'} sign_in_button mt-2`}
                                                            variant="contained"
                                                            disabled={disableResend}
                                                            size='small'
                                                            onClick={(e) => {
                                                                setResendTime(30);
                                                                setDisableResend(true);
                                                                sendOTPHandler(e, SCHEDULE_PROPERTY_VISIT_OTP_TYPE.RESEND)
                                                            }}
                                                        >
                                                            Resend
                                                        </Button>)}
                                                </div>
                                            </Box>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item md={4} sm={12}>
                                    <div>
                                        <InputLabel htmlFor="name" style={{ color: '#1F1F1F' }}>Enter Your Name <AsterikLabel /></InputLabel>
                                        <TextField
                                            required
                                            id="outlined-required"
                                            placeholder="Name"
                                            fullWidth
                                            size='small'
                                            name="name"
                                            className="form-control mb-2"
                                            type="text"
                                            disabled={isLoading || !isValidOTP.status}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.name}
                                        />
                                        {formik.touched.name && formik.errors.name && (
                                            <span className="text-danger">{formik.errors.name}</span>
                                        )}
                                    </div>
                                    <div className='icon_input'>
                                        <InputLabel htmlFor="email" style={{ color: '#1F1F1F' }}>Enter Your Email Address</InputLabel>
                                        <TextField
                                            required
                                            id="outlined-required"
                                            placeholder="____@gmail.com"
                                            fullWidth
                                            size='small'
                                            name="email"
                                            className="form-control"
                                            type="text"
                                            disabled={isLoading || !isValidOTP.status}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.email}
                                            error={
                                                !!(formik.touched?.email && formik.errors?.email)
                                            }
                                            helperText={
                                                formik.touched?.email && formik.errors?.email
                                                    ? formik.errors?.email
                                                    : ''
                                            }
                                        />
                                        <DraftsOutlined />
                                    </div>
                                    <div className='my-2'>
                                        <InputLabel htmlFor="email" style={{ color: '#1F1F1F' }}>Enter Your Password <AsterikLabel /> </InputLabel>
                                        <TextField
                                            name="password"
                                            value={formik.values.password}
                                            type="password"
                                            placeholder="New Password"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            variant="outlined"
                                            size="small"
                                            disabled={isLoading || !isValidOTP.status}
                                            fullWidth
                                            error={
                                                !!(formik.touched?.password && formik.errors?.password)
                                            }
                                            helperText={
                                                formik.touched?.password && formik.errors?.password
                                                    ? formik.errors?.password
                                                    : ''
                                            }
                                        />
                                    </div>
                                    <div className='icon_input'>
                                        <InputLabel htmlFor="phone" style={{ color: '#1F1F1F' }}>Enter Promo Code</InputLabel>
                                        <TextField
                                            id="outlined-required"
                                            placeholder="Promo Code"
                                            fullWidth
                                            size='small'
                                            name="promoCode"
                                            className="form-control mb-3"
                                            type="text"
                                            disabled={isLoading || !isValidOTP?.status}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.promoCode}
                                        />
                                        <CallOutlined />
                                    </div>
                                    <div className='my-2'>
                                        <InputLabel htmlFor="adhar">Upload Aadhar Card</InputLabel>
                                        <FileButton
                                            component="label"
                                            variant="contained"
                                            disabled={isLoading || !isValidOTP.status}
                                            role={undefined}
                                        >
                                            Choose file
                                            <VisuallyHiddenInput type="file" onChange={handleFileChange} />
                                        </FileButton>
                                        <span style={{ marginLeft: 16 }}>{fileName}</span>
                                    </div>
                                </Grid>
                                <Grid item md={4} sm={12}>
                                    <div>
                                        <InputLabel htmlFor="visitDetails" style={{ color: '#1F1F1F' }}>Tenant Type <AsterikLabel /></InputLabel>
                                        <Autocomplete
                                            name='type'
                                            disabled={isLoading || !isValidOTP.status}
                                            value={formik.values?.type}
                                            onChange={(event, selecteValue) => {
                                                formik.setFieldValue('type', selecteValue);
                                            }}
                                            onBlur={() => {
                                                if (!formik.touched?.type) {
                                                    formik.setFieldTouched('type', true, true);
                                                }
                                            }}
                                            renderOption={(props, option) => (
                                                <Box component='li' key={option} {...props}>
                                                    {option}
                                                </Box>
                                            )}
                                            isOptionEqualToValue={(option, value) =>
                                                TENANT_TYPES[option] === value
                                            }
                                            options={Object.values(TENANT_TYPES)}
                                            renderInput={(params) => {
                                                return (
                                                    <TextField
                                                        placeholder='Tenant Type'
                                                        {...params}
                                                    />
                                                );
                                            }}
                                            size='small'
                                        />
                                        {formik.touched.type && formik.errors.type && (
                                            <span className="text-danger">{formik.errors.type}</span>
                                        )}
                                    </div>
                                    <div className='my-2'>
                                        <div className='my-2'>
                                            <InputLabel htmlFor="visitDetails" style={{ color: '#1F1F1F' }}>Joining Date <AsterikLabel /></InputLabel>
                                            <Box display="flex" gap={2} className="schedule_date_time">
                                                <Grid item sm={12}>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DemoContainer components={['DatePicker']}>
                                                            <DatePicker
                                                                name="leaving_date"
                                                                format="DD/MM/YYYY"
                                                                disabled={isLoading || !isValidOTP.status}
                                                                value={formik?.values?.joining_date ? formik?.values?.joining_date : null}
                                                                slotProps={{
                                                                    textField: {
                                                                        clearable: true,
                                                                        size: 'small', variant: "outlined", onBlur: () => {
                                                                            if (!formik.touched?.joining_date) {
                                                                                formik.setFieldTouched("joining_date", true, false);
                                                                            }
                                                                        },
                                                                        onClear: () => {
                                                                            formik.setFieldValue(
                                                                                "joining_date",
                                                                                ""
                                                                            );
                                                                        },
                                                                    }
                                                                }}
                                                                className='w-100'
                                                                onChange={(e) => {
                                                                    formik.setFieldValue("joining_date", e ? dayjs(e) : "");
                                                                    if (!formik.touched?.joining_date) {
                                                                        formik.setFieldTouched("joining_date", true, false);
                                                                    }
                                                                }}
                                                                required
                                                            />
                                                        </DemoContainer>
                                                    </LocalizationProvider>
                                                    {formik.touched.joining_date && formik.errors.joining_date && (
                                                        <span className="text-danger">{formik.errors.joining_date}</span>
                                                    )}
                                                    <div className='my-2'>
                                                        <InputLabel htmlFor="email" style={{ color: '#1F1F1F' }}>Confirm Password <AsterikLabel /> </InputLabel>
                                                        <TextField
                                                            name="confirm_password"
                                                            value={formik.values.confirm_password}
                                                            type="password"
                                                            disabled={isLoading || !isValidOTP.status}
                                                            placeholder="Confirm Password"
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            variant="outlined"
                                                            size="small"
                                                            fullWidth
                                                            error={
                                                                !!(formik.touched?.confirm_password && formik.errors?.confirm_password)
                                                            }
                                                            helperText={
                                                                formik.touched?.confirm_password && formik.errors?.confirm_password
                                                                    ? formik.errors?.confirm_password
                                                                    : ''
                                                            }
                                                        />
                                                    </div>

                                                    <div className='my-2'>
                                                        <InputLabel htmlFor="address" style={{ color: '#1F1F1F' }}>Address</InputLabel>
                                                        <TextField
                                                            required
                                                            id="outlined-required"
                                                            placeholder="Address"
                                                            disabled={isLoading || !isValidOTP.status}
                                                            name='address'
                                                            value={formik.values.address}
                                                            onChange={formik.handleChange}
                                                            fullWidth
                                                            size='small'
                                                        />
                                                    </div>
                                                </Grid>
                                            </Box>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                            <div className="payment_btn payment_area book_payment_btn my-3 text-center">
                                <Button
                                    style={{ width: '238px' }}
                                    disabled={ isLoading || !(formik.isValid && (isValidOTP.status && (isValidOTP.contact_number === otpExpiresAt.contact_number)))}
                                    className={(!isLoading && formik.isValid && (isValidOTP.status && isValidOTP.contact_number === otpExpiresAt.contact_number)) ? 'theme_button sign_in_button' : ''}
                                    variant="contained"
                                    onClick={handleBooking}
                                >Book Now</Button>
                            </div>
                        </Card>
                    </div>
                </Container>
            </section>
        </div>
    );
};


export default BookForm;