import React, {useState, useEffect} from 'react';
import Slider from "react-slick";
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import DoubleQuote from "../../assets/images/double_quote.png";
import ProfilePic from "../../assets/images/profile.png";


const Testimonial = () => {
    const [currentPage, setCurrentPage] = useState(0);
    // const settings = {
    //     dots: false,
    //     infinite: true,
    //     speed: 1000,
    //     slidesToShow: 3,
    //     slidesToScroll: 1,
    //     autoplay: false,
    //     arrows: false,
    //     adaptiveHeight: true,
    //     responsive: [
    //         {
    //             breakpoint: 1024,
    //             settings: {
    //                 slidesToShow: 3,
    //                 slidesToScroll: 1,
    //                 dots: true
    //             }
    //         },
    //         {
    //             breakpoint: 768,
    //             settings: {
    //                 slidesToShow: 1,
    //                 slidesToScroll: 1,
    //                 dots: true
    //             }
    //         }
    //     ]
    // };
    const testimonials = [
        {
          profilePic: require('../../assets/images/profile.png'),
          doubleQuote:require('../../assets/images/double_quote.png'),
          content: "Lorem ipsum dolor sit amet consectetur. Adipiscing ut nisi leo nibh eros in.",
          clientName: "Francis Towne",
          position: "Student"
        },
        {
          profilePic: require('../../assets/images/profile.png'),
          doubleQuote: require('../../assets/images/double_quote.png'),
          content: "Lorem ipsum dolor sit amet consectetur. Adipiscing ut nisi leo nibh eros in. ",
          clientName: "Emily Smith",
          position: "Designer"
        },
        {
            profilePic: require('../../assets/images/profile.png'),
            doubleQuote: require('../../assets/images/double_quote.png'),
            content: "Lorem ipsum dolor sit amet consectetur. Adipiscing ut nisi leo nibh eros in. ",
            clientName: "Emily Smith",
            position: "Designer"
          },
          {
            profilePic: require('../../assets/images/profile.png'),
            doubleQuote: require('../../assets/images/double_quote.png'),
            content: "Lorem ipsum dolor sit amet consectetur. Adipiscing ut nisi leo nibh eros in. ",
            clientName: "Emily Smith",
            position: "Designer"
          },
          {
            profilePic: require('../../assets/images/profile.png'),
            doubleQuote: require('../../assets/images/double_quote.png'),
            content: "Lorem ipsum dolor sit amet consectetur. Adipiscing ut nisi leo nibh eros in. ",
            clientName: "Emily Smith",
            position: "Designer"
          },
          {
            profilePic: require('../../assets/images/profile.png'),
            doubleQuote: require('../../assets/images/double_quote.png'),
            content: "Lorem ipsum dolor sit amet consectetur. Adipiscing ut nisi leo nibh eros in. ",
            clientName: "Francis Towne",
            position: "Designer"
          },
          {
            profilePic: require('../../assets/images/profile.png'),
            doubleQuote: require('../../assets/images/double_quote.png'),
            content: "Lorem ipsum dolor sit amet consectetur. Adipiscing ut nisi leo nibh eros in. ",
            clientName: "Francis Towne",
            position: "Designer"
          }
        // Add more testimonials here
      ];
      const cardsPerPage = 3;
      const totalPages = Math.ceil(testimonials.length / cardsPerPage);
      useEffect(() => {
        const autoSlide = setInterval(() => {
          handleNext();
        }, 6000);
        return () => clearInterval(autoSlide);
      }, [currentPage]);
      
      const handleNext = () => {
        if (currentPage < totalPages - 1) {
          setCurrentPage(currentPage + 1);
        } else {
          setCurrentPage(0); // Loop back to the first slide
        }
      };
      
      const handlePrev = () => {
        if (currentPage > 0) {
          setCurrentPage(currentPage - 1);
        } else {
          setCurrentPage(totalPages - 1); // Loop back to the last slide
        }
      };
      
      const handleDotClick = (index) => {
        setCurrentPage(index);
      };
    return (
        <Container>
            <div className='testimonials testimonial-wrapper'>
                {/* <Slider {...settings}> */}
                <div className='testimonial_slider'>
                   <div className="home_info_cards testimonial_card-container"  style={{
                     transform: `translateX(-${currentPage * 100}%)`,
                     transition: 'transform 0.5s ease-in-out',
                     }}>
                    {testimonials.map((testimonial, index) => (
                        // <Card className="single_testimonial slider_card" key={index}>
                        //     <CardContent className='d-flex'>
                        //         <Avatar alt={ProfilePic} src={ProfilePic} className="avatar me-3" />
                        //         <div className='single_testimonial_content'>
                        //             <Typography variant="body1">
                        //                 <img src={DoubleQuote} alt={DoubleQuote} />
                        //             </Typography>
                        //             <Typography variant="body2" className="name">
                        //                 Lorem ipsum dolor sit amet consectetur. Adipiscing ut nisi leo nibh eros in. 
                        //             </Typography>
                        //             <Typography variant="body2" color="textPrimary" className='client_name'>
                        //                 Francis Towne
                        //             </Typography>
                        //             <Typography variant="body2" color="textSecondary">
                        //                 Student
                        //             </Typography>
                        //         </div>
                        //     </CardContent>
                        // </Card>
                        <div className="single_testimonial testimonial_slider_card" key={index}>
                            <CardContent className='d-flex'>
                                {/* <Avatar 
                                alt={testimonial.clientName} 
                                src={testimonial.profilePic} 
                                className="avatar me-3" 
                                /> */}
                                <div className='single_testimonial_content'>
                                {/* <Typography variant="body1">
                                    <img 
                                    src={testimonial.doubleQuote} 
                                    alt="Double Quote Icon" 
                                    />
                                </Typography> */}
                                <Typography variant="body2" className="name" style={{ fontStyle: 'italic' }}>
                                    {testimonial.content}
                                </Typography>
                                <Typography variant="body2" color="textPrimary" className='client_name'>
                                    {testimonial.clientName}
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    {testimonial.position}
                                </Typography>
                                </div>
                            </CardContent>
                        </div>
                        ))}
                    </div>
                </div>
                <div className="controls">
                    <button className="prev" onClick={handlePrev} disabled={currentPage === 0}>
                    &#10094;
                    </button>

                    <div className="dots">
                    {Array.from({ length: totalPages }).map((_, index) => (
                        <span
                        key={index}
                        className={`dot ${currentPage === index ? 'active' : ''}`}
                        onClick={() => handleDotClick(index)}
                        ></span>
                    ))}
                    </div>

                    <button className="next" onClick={handleNext} disabled={currentPage === totalPages - 1}>
                    &#10095;
                    </button>
                </div>
                    {/* <Card className="single_testimonial">
                        <CardContent className='d-flex'>
                            <Avatar alt={ProfilePic} src={ProfilePic} className="avatar me-3" />
                            <div className='single_testimonial_content'>
                                <Typography variant="body1" className="quote">
                                    <img src={DoubleQuote} alt={DoubleQuote} />
                                </Typography>
                                <Typography variant="body2" className="name">
                                    Lorem ipsum dolor sit amet consectetur. Adipiscing ut nisi leo nibh eros in. 
                                </Typography>
                                <Typography variant="body2" color="textPrimary" className='client_name'>
                                    Francis Towne
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    Student
                                </Typography>
                            </div>
                        </CardContent>
                    </Card>
                    <Card className="single_testimonial">
                        <CardContent className='d-flex'>
                            <Avatar alt={ProfilePic} src={ProfilePic} className="avatar me-3" />
                            <div className='single_testimonial_content'>
                                <Typography variant="body1" className="quote">
                                    <img src={DoubleQuote} alt={DoubleQuote} />
                                </Typography>
                                <Typography variant="body2" className="name">
                                    Lorem ipsum dolor sit amet consectetur. Adipiscing ut nisi leo nibh eros in. 
                                </Typography>
                                <Typography variant="body2" color="textPrimary" className='client_name'>
                                    Francis Towne
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    Student
                                </Typography>
                            </div>
                        </CardContent>
                    </Card> */}
                {/* </Slider> */}
            </div>
        </Container>
    )
}

export default Testimonial;