import React from 'react';
import { Dashboard } from '../pages/Dashboard';
import { PropertyDetail } from '../pages/Detail';
import { BookRoom } from '../pages/Detail/bookRoom';
import BookForm from '../pages/Detail/bookingForm';
import { ScheduleForm } from '../pages/Detail/scheduleForm';
import { EnlistYourProperty } from '../pages/EnlistProperty';
import { OtpIndex } from '../pages/EnlistProperty/otpIndex';
import { PropertyRequest } from '../pages/EnlistProperty/propertyRequest';
import About  from "../pages/About";
  
const publicRoutes = [
  { path: '/', component: <Dashboard /> },
  { path: '/property-detail', component: <PropertyDetail /> },
  { path: '/book-room', component: <BookRoom /> },
  { path: '/book-form', component: <BookForm /> },
  { path: '/schedule-form', component: <ScheduleForm /> },
  { path: '/enlist-your-property', component: <EnlistYourProperty /> },
  { path: '/otp-page', component: <OtpIndex /> },
  { path: '/property-request', component: <PropertyRequest /> },
  { path: '/about-us', component: <About /> }
];

export { publicRoutes };
